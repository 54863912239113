.editorial-grid-3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr auto;
    grid-auto-columns: 1fr;
    gap: 40px 10px;
    grid-auto-flow: row;

    	@media screen and (min-width: 768px){
            gap: 40px 20px;
        }
}

.img1 { grid-area: 1 / 1 / 2 / 2; }

.img2 { grid-area: 1 / 2 / 2 / 3; }

.img3 { grid-area: 2 / 1 / 3 / 3; }


.editorial-grid-2 {  display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 40px 20px;
    grid-auto-flow: row;
}

.img3 { grid-area: 1 / 1 / 2 / 2; }

.img4 { grid-area: 1 / 2 / 2 / 3; }



.container-rolex.editorial-grid__container{
    max-width: calc(1180rem/16) !important;
}

.editorial-grid__title{
    text-transform: uppercase;
    margin-top: calc(10rem/16);
    margin-bottom: 0;

    font-size: calc(13rem/16);
    color: #111;

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }


}
.editorial-grid__small-title{
    color: #111;
    margin-bottom: calc(10rem/16);
    font-size: calc(16rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: calc(30rem/16);
        font-size: calc(16rem/16);
    }
}

.editorial-grid__item:hover img{
     overflow: hidden;
     transform: scale(1.05);
 }

.editorial-grid__item img{
    transition: transform 0.3s ease-out;
    transform: scale(1);
}