.rolex-feature-selection{
    @media screen and (max-width: 767px){
      padding: 0 1rem;
    }
}

.rolex-feature-selection__link{
    text-transform: uppercase;
    font-family: var(--font-regular);
    font-size: 1.5rem;
    line-height: 2.5;
    @media screen and (max-width: 767px){
        font-size: 0.75rem;
    }
}