.rolex-store-accordion .card-body{
    padding: 0;
    margin-bottom: calc(40rem/16);
}

.rolex-store-accordion .card{
    border: none;
    border-bottom: 1px solid var(--color-rolex-grey);
}

.rolex-store-accordion .card-header{
    border: none;
    background: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(10rem/16);
    margin-top: calc(10rem/16);
}
.rolex-store-accordion .card-header:hover .rolex-store-accordion__hl{
   text-decoration: none;
    color: var(--color-rolex-primary);
}

.rolex-store-accordion .btn-link{
    padding: 0;
}
.rolex-store-accordion .btn-link:focus{
    text-decoration: none;
}

.rolex-store-accordion__hl{
    text-transform: uppercase;
    color: black;
    font-family: var(--font-bold);
    margin-bottom: 0;
    font-size: calc(16rem/16);
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }

}

.rolex-store-accordion__links{
  display: flex;
    justify-content: center;
    margin-top: calc(10rem/16);
}


.rolex-store-accordion__link{
    margin: 0 calc(5rem/16);
}

.rolex-store-accordion__link:hover{
    cursor: pointer;
}


.rolex-store-accordion__item{
    background-color: var(--color-rolex-light-grey);
    min-height: calc(360rem/16);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(20rem/16);
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(40rem/16);
    }
}

.rolex-store-accordion__subtitle{
    text-transform: uppercase ;
    font-size: calc(11rem/16);
    line-height: 1;

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }

}

.rolex-store-accordion__title{

    text-transform: uppercase;
    color: var(--color-rolex-default-light);
    margin-top: calc(10rem/16);
    margin-bottom: 0;
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.rolex-store-accordion__description{
    margin-bottom: calc(10rem/16);
    margin-top: calc(10rem/16);
}


.card .card-header .rolex-store-accordion__hl .icon-rolex-arrow-right {
    transform: rotate(270deg);
    font-size: calc(10rem/16);
    padding-right: calc(5rem/16);
}

.card .card-header .collapsed.rolex-store-accordion__hl .icon-rolex-arrow-right{
    transform: rotate(90deg);
}
