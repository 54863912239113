.rolex-image-text-column__img--left{
    padding-left: 5vw;
    width: 100%;
    @media screen and (max-width: 767px){
        padding-left: 5vw;
        padding-right: 5vw;
        margin-bottom: calc(20rem/16);
    }
}

.rolex-image-text-column__img--left img,
.rolex-image-text-column__img--right img{
    width: 100%;
}

.rolex-image-text-column__img--right{
    width: 100%;
    padding-right: 5vw;

    @media screen and (max-width: 767px){
        padding-left: 5vw;
        padding-right: 5vw;
        margin-bottom: calc(20rem/16);
    }
}

.rolex-image-text-column__body{
    height: 100%;
    padding-left: 5vw;
    padding-right: 5vw;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: 992px){
        padding-left: 10vw;
        padding-right: 10vw;
    }


   @media screen and (min-width: 768px){
       padding-left: 5vw;
       padding-right: 5vw;
    }
}

.rolex-image-text-column__body .navbar-brand img{
    max-height: calc(60rem/16);
    max-width: calc(120rem/16);
}

.rolex-image-text-column__content{
    line-height: 1.8;
    font-size: calc(16rem/16);

}



