.video-overlay {
    cursor: pointer;
    position: relative;
    padding-right: 5vw;
    padding-left: 5vw;
}

.video-overlay--small{
    max-width: calc(1340rem/16);
    margin: 0 auto;
}

.video-overlay__play-button {
    position: absolute;
    transform-origin: top left;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    transition: transform 350ms ease, box-shadow 200ms ease;
    z-index: 4;
    cursor: pointer;
    width: calc(80rem/16);
    height: calc(80rem/16);
    background-color: white;
    border-radius: 50%;
    color: var(--color-primary);
}


.video-overlay__icon {
   position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    font-size: calc(20rem/16);
    transition: transform 200ms ease;
    @media screen and (min-width:768px){
        font-size: calc(22rem/16);
    }
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    width: calc(25rem/16);
    height: calc(30rem/16);
    background-color: var(--color-rolex-primary);
}


.video-overlay:hover .video-overlay__play-button {
    box-shadow: 0 calc(20rem / 16) calc(40rem / 16) 0 rgba(29, 29, 27, 0.7);
}


.video-overlay__overlay {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.2s ease;
    padding-right: 5vw;
    padding-left: 5vw;
}

.overlay-hide .video-overlay__overlay,
.overlay-hide .video-overlay__play-button,
.video-overlay:after {
    display: none;
    pointer-events: auto;
}

.video-overlay.overlay-hide{
    pointer-events: auto;
}

.video-overlay--tutorial{
    margin-bottom: calc(24rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(36rem/16);
    }
}
