.rolex-image-text-full-width__img img{
    width: 100%;
}


.rolex-image-text-full-width__body{
    height: 100%;
    padding: calc(20rem/16) 5vw;
    background-color: var(--color-rolex-light-black);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

@media screen and (min-width: 992px){
    padding-left: 10vw;
    padding-right: 10vw;
}


@media screen and (min-width: 768px){
    padding-left: 5vw;
    padding-right: 5vw;
}
}

.rolex-image-text-full-width__hl{
    font-size: calc(26rem/16);
    margin-bottom: calc(10rem/16);
    text-transform: uppercase;

@media screen and (min-width: 768px){
    margin-bottom: calc(10rem/16);
}
}


.rolex-image-text-full-width__content{
    line-height: 1.8;
    font-size: calc(16rem/16);
}
