.rolex-image-text-area__title{
    margin-top: calc(20rem/16);;
    color: var(--color-rolex-default);
    text-transform: uppercase ;
    font-size: calc(24rem/16);
    margin-bottom: calc(20rem/16);
    position: relative;
    text-align: center;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);

	@media screen and (min-width: 768px){
        font-size: calc(38rem/16);
    }

@media screen and (min-width: 768px){
    font-size: calc(36rem/16);
    margin-bottom: calc(14rem/16);
}
}

.rolex-image-text-area__container{
    max-width: calc(650rem/16);
    margin: 0 auto;
}

.rolex-image-text-area__img{
    @media screen and (max-width: 767px){
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
}

.rolex-image-text-area__content{
    @media screen and (max-width: 767px){
        padding: 0 calc(20rem/16);
    }
    line-height: 1.8;
    font-size: calc(16rem/16);
}

.rolex-image-text-area__content p {
    color: black;
}

.rolex-image-text-area__bg-grey{
    background-color: var(--color-rolex-light-grey);
    padding-bottom: calc(50rem/16);
    padding-top: calc(50rem/16);


@media screen and (min-width: 768px){
    padding-bottom: calc(100rem/16);
    padding-top: calc(100rem/16);
}
}

.btn.rolex-image-text-area__btn{
    text-transform: none;
    margin-top: calc(20rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(30rem/16);
    }
}