.map-area{
    margin-left: calc(20rem/16);
    margin-right: calc(20rem/16);
    background-color: var(--color-rolex-light-grey);


    	@media screen and (min-width: 768px){
            height: calc(550rem/16);
            margin-left: 5vw;
            margin-right: 5vw;
    	}
}

.map-subtitle{
    color: var(--color-rolex-primary);
    @media screen and (max-width: 767px){
        margin-bottom: calc(5rem/16);
    }
}

.rolex-map__wrapper{
    height: calc(300rem/16);
    width: 100%;
    overflow: hidden;
    @media screen and (min-width: 768px){
        height: calc(550rem/16);
    }
}

.rolex-map{
    height: calc(300rem/16);
    width: 100%;
    @media screen and (min-width: 768px){
        height: calc(550rem/16);
    }
}

.map-title{

}

.map-collapse{
    position: absolute;
    width: 70%;
    z-index: 3;
    background-color: white;
    padding: calc(15rem/16) calc(10rem/16);
    @media screen and (max-width: 767px){
        left: 50%;
        transform: translateX(-50%);
    }
}

.map-address{
    font-size: calc(16rem/16);
    margin-bottom: calc(10rem/16);

    @media screen and (max-width: 767px){
        text-align: center;
        line-height: 1.2;
    }
}

.map-city{

}

.map-country{

}

.map-icon-link{
    display: flex;
    align-items: center;
    margin-bottom: calc(15rem/16);
}

.map-info{

    display: flex;
    flex-direction: column;
    justify-content: center;

	@media screen and (min-width: 768px){
        margin-left: calc(20rem/16);
        height: 100%;

    }
    @media screen and (max-width: 767px){
        align-items: center;
        margin-top: calc(30rem/16);
        margin-bottom: calc(40rem/16);
    }
}


@media screen and (max-width: 767px){
    .map-links{
        display: flex;
    }
}

.map-open-hours{
    margin-bottom: calc(20rem/16);
    font-size: calc(12rem/16);

    	@media screen and (min-width: 768px){
            font-size: calc(16rem/16);
        }
}
.map-open-hours .map-open-hours__collapse:focus{
    outline: none;
    box-shadow: none;
}

.map-open-hours .icon{
    transform: rotate(90deg);
    font-size: calc(8rem/16);
    margin-left: calc(10rem/16);
    @media screen and (min-width: 768px){
        margin-left: calc(10rem/16);
    }
}

.map-area .icon{
    @media screen and (max-width: 767px){
        margin-left: calc(10rem/16);
    }
}

.map-area .icon-close{
    transform: rotate(45deg);
}


.map-links .map-icon-link:focus {
    box-shadow: none;
}
