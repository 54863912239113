.rolex-pagination{
    display: flex;
    margin-top: calc(50rem /16);
    @media screen and (min-width: 768px){
        display: flex;
        margin-top: calc(20rem /16);
    }
}

.rolex-pagination li {
    color: var(--color-text-default);
}

.rolex-pagination .page-link {
    background: none;
    color: var(--color-text-default);
    border: none;
    display: inline-block;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
    opacity: 1;
    margin-left: 0;
}

.page-link--prev .icon-arrow_green{
    transform: rotate(180deg);
}

.rolex-pagination .page-link:focus{
    outline: none;
    box-shadow: none;
}

.rolex-pagination .page-item.active .page-link {
    background: none;
    color: var(--color-text-default);
    opacity: 1;
    font-size: calc(18rem /16);
    padding-top: calc(2rem /16);
}

.rolex-pagination .page-link:hover {
    opacity: .3;
}

.rolex-pagination .page-item:first-child .page-link, .rolex-pagination .page-item:last-child .page-link {
    opacity: 1;
}

.rolex-pagination .page-item.disabled .page-link {
    color: var(--color-text-default);
    border: none;
    opacity: 1;
}