@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1738150838047/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1738150838047/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1738150838047/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-arrow-down:before { content: "\EA01" }
.icon-arrow-dropdown:before { content: "\EA02" }
.icon-arrow-left:before { content: "\EA03" }
.icon-arrow-right:before { content: "\EA04" }
.icon-arrow-up:before { content: "\EA05" }
.icon-arrow:before { content: "\EA06" }
.icon-arrow_green:before { content: "\EA07" }
.icon-bottle:before { content: "\EA08" }
.icon-burger:before { content: "\EA09" }
.icon-calendar:before { content: "\EA0A" }
.icon-check:before { content: "\EA0B" }
.icon-close:before { content: "\EA0C" }
.icon-contact-us:before { content: "\EA0D" }
.icon-dutyfree:before { content: "\EA0E" }
.icon-elements:before { content: "\EA0F" }
.icon-fb:before { content: "\EA10" }
.icon-find-a-store:before { content: "\EA11" }
.icon-fullscreen:before { content: "\EA12" }
.icon-globe:before { content: "\EA13" }
.icon-google-plus:before { content: "\EA14" }
.icon-heart-left:before { content: "\EA15" }
.icon-heart-right:before { content: "\EA16" }
.icon-icon-print:before { content: "\EA17" }
.icon-info:before { content: "\EA18" }
.icon-information_icon_green:before { content: "\EA19" }
.icon-information_icon_white:before { content: "\EA1A" }
.icon-instagram:before { content: "\EA1B" }
.icon-location:before { content: "\EA1C" }
.icon-mail:before { content: "\EA1D" }
.icon-menu-bar:before { content: "\EA1E" }
.icon-menu-title:before { content: "\EA1F" }
.icon-phone-filled:before { content: "\EA20" }
.icon-phone:before { content: "\EA21" }
.icon-present:before { content: "\EA22" }
.icon-reset-icon:before { content: "\EA23" }
.icon-rolex-2024-arrow:before { content: "\EA24" }
.icon-rolex-2024-download:before { content: "\EA25" }
.icon-rolex-2024-find-us:before { content: "\EA26" }
.icon-rolex-2024-info:before { content: "\EA27" }
.icon-rolex-arrow-left:before { content: "\EA28" }
.icon-rolex-arrow-right:before { content: "\EA29" }
.icon-rolex-icon-11px-whatsapp:before { content: "\EA2A" }
.icon-rolex-icon-15px-whatsapp:before { content: "\EA2B" }
.icon-rolex-icon-21px-whatsapp:before { content: "\EA2C" }
.icon-rolex-mail:before { content: "\EA2D" }
.icon-rolex-phone:before { content: "\EA2E" }
.icon-schnoerkel-header:before { content: "\EA2F" }
.icon-schnoerkel:before { content: "\EA30" }
.icon-search:before { content: "\EA31" }
.icon-store-selected:before { content: "\EA32" }
.icon-store:before { content: "\EA33" }
.icon-twitter:before { content: "\EA34" }
.icon-vip:before { content: "\EA35" }
.icon-watch:before { content: "\EA36" }
.icon-youtube:before { content: "\EA37" }


:root {
--icon-arrow-down: "\EA01";
    --icon-arrow-dropdown: "\EA02";
    --icon-arrow-left: "\EA03";
    --icon-arrow-right: "\EA04";
    --icon-arrow-up: "\EA05";
    --icon-arrow: "\EA06";
    --icon-arrow_green: "\EA07";
    --icon-bottle: "\EA08";
    --icon-burger: "\EA09";
    --icon-calendar: "\EA0A";
    --icon-check: "\EA0B";
    --icon-close: "\EA0C";
    --icon-contact-us: "\EA0D";
    --icon-dutyfree: "\EA0E";
    --icon-elements: "\EA0F";
    --icon-fb: "\EA10";
    --icon-find-a-store: "\EA11";
    --icon-fullscreen: "\EA12";
    --icon-globe: "\EA13";
    --icon-google-plus: "\EA14";
    --icon-heart-left: "\EA15";
    --icon-heart-right: "\EA16";
    --icon-icon-print: "\EA17";
    --icon-info: "\EA18";
    --icon-information_icon_green: "\EA19";
    --icon-information_icon_white: "\EA1A";
    --icon-instagram: "\EA1B";
    --icon-location: "\EA1C";
    --icon-mail: "\EA1D";
    --icon-menu-bar: "\EA1E";
    --icon-menu-title: "\EA1F";
    --icon-phone-filled: "\EA20";
    --icon-phone: "\EA21";
    --icon-present: "\EA22";
    --icon-reset-icon: "\EA23";
    --icon-rolex-2024-arrow: "\EA24";
    --icon-rolex-2024-download: "\EA25";
    --icon-rolex-2024-find-us: "\EA26";
    --icon-rolex-2024-info: "\EA27";
    --icon-rolex-arrow-left: "\EA28";
    --icon-rolex-arrow-right: "\EA29";
    --icon-rolex-icon-11px-whatsapp: "\EA2A";
    --icon-rolex-icon-15px-whatsapp: "\EA2B";
    --icon-rolex-icon-21px-whatsapp: "\EA2C";
    --icon-rolex-mail: "\EA2D";
    --icon-rolex-phone: "\EA2E";
    --icon-schnoerkel-header: "\EA2F";
    --icon-schnoerkel: "\EA30";
    --icon-search: "\EA31";
    --icon-store-selected: "\EA32";
    --icon-store: "\EA33";
    --icon-twitter: "\EA34";
    --icon-vip: "\EA35";
    --icon-watch: "\EA36";
    --icon-youtube: "\EA37";
    
}