.container-contact{
    margin: 0 auto;
    max-width: calc(650rem/16);
}

.rolex-contact-form__hl{
    font-size: calc(24rem/16);

    	@media screen and (min-width: 768px){
    	    font-size: calc(28rem/16);
    	}
    @media screen and (max-width: 767px){
        margin-bottom: calc(15rem/16);
    }
}

.rolex-contact-form {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 60px 60px 60px 85px 136px;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 0px 20px;
    grid-auto-flow: row;
    grid-template-areas:
    "rolex-contact-form__title rolex-contact-form__firstname"
    "rolex-contact-form__lastname rolex-contact-form__lastname"
    "rolex-contact-form__email rolex-contact-form__email "
    "rolex-contact-form__phonecode rolex-contact-form__phone-number"
    "rolex-contact-form__message rolex-contact-form__message";


    	@media screen and (min-width: 768px){
            grid-template-columns: 1fr 2fr 1fr 2fr;
            grid-template-rows: 80px 80px 190px;
            grid-auto-columns: 1fr;
            grid-auto-rows: 1fr;
            gap: 0px 20px;
            grid-auto-flow: row;
            grid-template-areas:
        "rolex-contact-form__title rolex-contact-form__firstname rolex-contact-form__lastname rolex-contact-form__lastname"
        "rolex-contact-form__email rolex-contact-form__email rolex-contact-form__phonecode rolex-contact-form__phone-number"
        "rolex-contact-form__message rolex-contact-form__message rolex-contact-form__message rolex-contact-form__message";

        }

}

.rolex-contact-form-store {

    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 60px 60px 60px 85px 60px 60px 60px 136px;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 0px 20px;
    grid-auto-flow: row;
    grid-template-areas:
        "rolex-contact-form-store__title rolex-contact-form-store__firstname"
        "rolex-contact-form-store__lastname rolex-contact-form-store__lastname"
        "rolex-contact-form-store__email rolex-contact-form-store__email"
        "rolex-contact-form-store__phonecode rolex-contact-form-store__number"
        "rolex-contact-form-store__country rolex-contact-form-store__country"
        "rolex-contact-form-store__city rolex-contact-form-store__city"
        "rolex-contact-form-store__address rolex-contact-form-store__address"
        "rolex-contact-form-store__message rolex-contact-form-store__message";

    	@media screen and (min-width: 768px){
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-rows: 1fr;
            grid-template-columns: 1fr 2fr 1fr 2fr;
            grid-template-rows: 80px 80px 80px 80px 190px;
            gap: 0px 20px;
            grid-auto-flow: row;
            grid-template-areas:
                "rolex-contact-form-store__title rolex-contact-form-store__firstname rolex-contact-form-store__lastname rolex-contact-form-store__lastname"
                "rolex-contact-form-store__email rolex-contact-form-store__email rolex-contact-form-store__phonecode rolex-contact-form-store__number"
                "rolex-contact-form-store__country rolex-contact-form-store__country rolex-contact-form-store__city rolex-contact-form-store__city"
                "rolex-contact-form-store__address rolex-contact-form-store__address rolex-contact-form-store__address rolex-contact-form-store__address"
                "rolex-contact-form-store__message rolex-contact-form-store__message rolex-contact-form-store__message rolex-contact-form-store__message";
        }
}

.rolex-contact-form-store__title {
    grid-area: rolex-contact-form-store__title;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}

.rolex-contact-form-store__firstname {
    grid-area: rolex-contact-form-store__firstname;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}

.rolex-contact-form-store__lastname {
    grid-area: rolex-contact-form-store__lastname;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}

.rolex-contact-form-store__email {
    grid-area: rolex-contact-form-store__email;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}

.rolex-contact-form-store__phonecode {
    grid-area: rolex-contact-form-store__phonecode;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}
.rolex-contact-form-store__phonecode span{
    position: absolute;
    top: calc(10rem/16);
    font-size: calc(15rem/16);
}

.rolex-contact-form-store__number {
    grid-area: rolex-contact-form-store__number;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}

.rolex-contact-form-store__country {
    grid-area: rolex-contact-form-store__country;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}

.rolex-contact-form-store__city {
    grid-area: rolex-contact-form-store__city;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}

.rolex-contact-form-store__address {
    grid-area: rolex-contact-form-store__address;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}

.rolex-contact-form-store__message {
    grid-area: rolex-contact-form-store__message;
    padding-top: calc(40rem/16);
    display: block;
    position: relative;

    @media screen and (min-width: 768px){
        padding-top: calc(60rem/16);
    }
}


.rolex-contact-form__title {
    grid-area: rolex-contact-form__title;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}

.rolex-contact-form__lastname {
    grid-area: rolex-contact-form__lastname;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;

}

.rolex-contact-form__firstname {
    grid-area: rolex-contact-form__firstname;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}

.rolex-contact-form__email {
    grid-area: rolex-contact-form__email;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;
}

.rolex-contact-form__email span,
.rolex-contact-form-store__email span{
    font-size: calc(15rem/16);
}


.rolex-contact-form__phonecode {
    grid-area: rolex-contact-form__phonecode;
    display: flex;
    align-items: end;
    flex-grow: 1;

    @media screen and (max-width: 767px){
        position: relative;
    }
}
.rolex-contact-form__phonecode span{
   position: absolute;
    top: calc(10rem/16);
    font-size: calc(15rem/16);
}

.rolex-contact-form__phone-number {
    grid-area: rolex-contact-form__phone-number;
    display: flex;
    align-items: end;
    flex-grow: 1;
    position: relative;

}

.rolex-contact-form__message {
    grid-area: rolex-contact-form__message;
    padding-top: calc(40rem/16);
    display: block;
    position: relative;

    	@media screen and (min-width: 768px){
            padding-top: calc(60rem/16);
        }

}

.rolex-contact-form .form-select:focus,
.rolex-contact-form-store .form-select:focus{
    outline: none;
}

.rolex-contact-form__checkboxes{
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}

.rolex-contact-form .form-control,
.rolex-contact-form-store .form-control{
    height: auto;
    padding: 0;
    box-shadow: none;
    border-bottom: 1px solid var(--color-rolex-grey);
}
.rolex-contact-form .form-group,
.rolex-contact-form-store .form-group{
    margin-bottom: 0;
    width: stretch;
}

.rolex-contact-form .form-group select,
.rolex-contact-form-store .form-group select{
    border: none;
    display: flex;
    justify-content: space-between;
    width: stretch;
    border-bottom: solid 1px var(--color-rolex-grey);
    font-size: calc(15rem/16);
    color: #5F5C62;
}
.rolex-contact-form .form-group textarea,
.rolex-contact-form-store .form-group textarea{
    border: solid 1px var(--color-rolex-grey);
    min-height: calc(70rem/16);

    	@media screen and (min-width: 768px){
            min-height: calc(100rem/16);
    	}
}
.rolex-contact-form .rolex-contact-form__message .form-group label,
.rolex-contact-form-store .rolex-contact-form-store__message .form-group label{
    font-size: calc(15rem/16);
}

.rolex-contact-form .form-group .control-label,
.rolex-contact-form-store .form-group .control-label{
    /*left: 0;
    top: calc(35rem/16);*/
    left: 0;
    top: calc(15rem/16);

    	@media screen and (min-width: 768px){
            top: calc(15rem/16);
            left: 0;
        }
}

.rolex-contact-form .form-control:focus ~ .control-label,
.rolex-contact-form .select ~ .control-label,
.rolex-contact-form .form-control.has-value ~ .control-label,
.rolex-contact-form .form-control.has-already-value ~ .control-label,
.rolex-contact-form-store .form-control:focus ~ .control-label,
.rolex-contact-form-store .select ~ .control-label,
.rolex-contact-form-store .form-control.has-value ~ .control-label,
.rolex-contact-form-store .form-control.has-already-value ~ .control-label {
    transform: translateY(-120%) scale(0.7);
    z-index: 3;

    @media screen and (min-width: 768px){
        transform: translateY(-150%) scale(0.7);
    }
}

.rolex-contact-form .parsley-errors-list,
.rolex-contact-form-store .parsley-errors-list{
    position: absolute;
    bottom: calc(-20rem/16);
    right: 0;
    font-size: 10px;
    white-space: nowrap;
    z-index: 2;
}

.rolex-contact-form .has-error .form-control,
.rolex-contact-form-store .has-error .form-control{
    border: none;
    border-bottom: var(--color-danger) 1px solid;
}

.rolex-contact-form__content{
    font-size: calc(16rem/16);
}


.rolex-contact-form__submit{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}

.rolex-contact-form__cancel{
    border: none;
    background: none;
    color: black;
    cursor: pointer;
    margin-right: calc(30rem/16);
    @media screen and (max-width: 767px){
        margin-top: calc(20rem/16);
    }
}
.rolex-contact-form__cancel:focus{
   outline: none;
}

.rolex-contact-form__required{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: calc(30rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}

.rolex-contact-form .required-symbol{
    top: 0;
    font-size: calc(14rem/16);
}

