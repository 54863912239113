.rolex-model-carousel {
    overflow: visible;
    position: relative;
    color: var(--color-text-default);

    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: auto 300px auto;
    gap: 0px 0px;
    grid-template-areas:
    "rolex-model-carousel__top"
    "rolex-model-carousel__slider"
    "rolex-model-carousel__bottom";


    @media screen and (min-width: 992px){
            height: calc(550rem/16);
            grid-template-columns: 50% 50%;
            grid-template-rows: auto auto;
            grid-auto-columns: 1fr;
            grid-auto-rows: 1fr;
            gap: 0px 0px;
            grid-auto-flow: row;
            grid-template-areas:
            "rolex-model-carousel__slider rolex-model-carousel__top"
            "rolex-model-carousel__slider rolex-model-carousel__bottom";
            color: white;

    	}
}

.rolex-model-carouse img{
    height: 100%;
}

.rolex-model-carousel__subtitle{
    margin-bottom: calc(20rem/16);
    font-size: calc(16rem/16);
    @media screen and (min-width: 992px){
        margin-bottom: calc(30rem/16);
        font-size: calc(16rem/16);
    }
}


.rolex-model-carousel__subtitle{
    margin-bottom: calc(10rem/16);
}

.rolex-model-carousel__slider {
    grid-area: rolex-model-carousel__slider;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    /*@media screen and (max-width: 991px){
        background-color: var(--color-main-dark);
    }*/
}

.rolex-model-carousel__top {
    padding-top: calc(20rem/16);
    grid-area: rolex-model-carousel__top;
    display: flex;
    flex-direction: column;
    justify-content: end;

    @media screen and (max-width: 991px){
        align-items: center;
    }

    @media screen and (min-width: 992px){
        padding-left: calc(200rem/16);
}
}


.rolex-model-carousel__bottom {
    padding-bottom: calc(10rem/16);
    grid-area: rolex-model-carousel__bottom;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media screen and (min-width: 992px){
        padding-left: calc(200rem/16);
    }
}

.rolex-model-carousel__slider .slick-next {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: var(--color-rolex-primary);
}

.rolex-model-carousel__slider .slick-prev {
    position: absolute;
    top: 50%;
    left: 5%;
    z-index: 5;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: var(--color-rolex-primary);
}


.rolex-model-carousel__slider .slick-arrow{
    background: none;
    border: none;
    color: white;
}

.rolex-model-carousel__slider .slick-arrow:focus{
    outline: none;
}

.rolex-model-carousel__slider .slick-dots{
    z-index: 500;
    position: absolute;
    bottom: calc(-38rem/16);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200%;

    @media screen and (min-width: 992px){
        display: none !important;
    }
}

.rolex-model-carousel__slider .slick-dots>li{
    width: calc(40rem / 16);
    height: calc(4rem / 16);
    position: relative;
    margin: 0 calc(4rem/16);
}

.rolex-model-carousel__slider .slick-dots>li button{
    width: calc(40rem / 16);
    height: calc(4rem / 16);
    background-color: var(--color-rolex-default);
    opacity: 0.2;
    border-radius: calc(5rem/16);
}

.rolex-model-carousel__slider .slick-dots>li.slick-active button{
    background-color: var(--color-primary-dark);
    opacity: 1;
}


.rolex-model-carousel__content{
    margin-bottom: calc(10rem/16);
    @media screen and (max-width: 991px){
        text-align: center;
    }
}

.rolex-model-carousel__content .icon:hover .rolex-model-carousel__info{
    display: block;
}


.rolex-model-carousel__info{
    z-index: 10;
    color: var(--color-text-default);
    font-family: var(--font-default);
    position: absolute;
    left: calc(-50rem/16);
    margin-top: calc(10rem/16);
    width: calc(300rem/16);
    height: auto;
    background-color: white;
    border-color: transparent transparent white transparent;
    border-radius: calc(4rem/16);
    padding: calc(10rem/16) calc(15rem/16);
    	
    @media screen and (min-width: 992px){
        width: calc(400rem/16);
    }
    @media screen and (max-width: 991px){
        transform: translateX(-50%);
        left:0;
    }
}

.rolex-model-carousel__link{
    display: flex;
    align-items: center;
    margin-bottom: calc(15rem/16);
   @media screen and (max-width: 991px){
       margin: calc(20rem/16) calc(10rem/16);
   }
}

.rolex-model-carousel__link .icon-find-a-store{
    font-size: calc(20rem/16);
}

@media screen and (max-width: 991px){
    .rolex-model-carousel__links{
        display: flex;
        justify-content: center;
    }
}

.rolex-model-carousel__links{
    margin-bottom: calc(10rem/16);
}

.rolex-model-carousel__btn {
    text-decoration: underline;
    display: block;
    margin-bottom: calc(20rem/16);
    width: fit-content;
    @media screen and (max-width: 991px){
        margin-bottom: calc(30rem/16);
    }
}

.rolex-model-carousel__contact-title{

        text-align: center;
        margin-top: calc(68rem/16);


    	@media screen and (min-width: 992px){
            display: none;
    	}
}


.rolex-model-slider-item{
    position: relative;
}


.rolex-model-carousel__info:hover::before {
   background-color: var(--color-rolex-light-grey);
}


.rolex-model-carousel__info{
    display: none;
}

.rolex-model-carousel__img-nav .slick-slide{
    width: calc(90rem/16)!important;
    height: auto;
}
.rolex-model-carousel__img-nav .slick-slide{
    width: calc(95rem/16)!important;
}

.rolex-model-carousel__img-nav .embed-responsive{
    width: calc(90rem/16);
    height: auto;
}

.rolex-model-carousel__img-nav .embed-responsive img{
    background: white;
}


.rolex-model-slider-item__img .embed-responsive:not(.rolex-model-carousel__img-item--transparent){

}

.rolex-model-slider-item__img .embed-responsive{
    @media screen and (min-width: 992px){
        height: calc(550rem/16);
    }
    @media screen and (max-width: 991px){
        height: calc(300rem/16);
    }
}
.embed-responsive-item.rolex-model-carousel__img-item{
    height: 100%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    width: auto;
    object-fit: cover;
}

.embed-responsive-item.rolex-model-carousel__img-item--transparent{
    object-fit: contain;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

.embed-responsive-item.rolex-teaser-slider-item__embed-nav-item{
    width: calc(90rem/16);
    height: auto;
}

.rolex-model-carousel__img-nav .slick-list{
    padding: 0!important;
}

.rolex-model-carousel__slider{
    @media screen and (max-width: 991px){
        overflow: visible !important;
    }
}

.rolex-model-carousel__slider .slick-list{
    overflow: visible !important;
}

.rolex-model-carousel__img-nav .slick-track{
    padding: 0!important;
    width: auto !important;
    transform: none !important;
}

.rolex-model-carousel__bg-img{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -4;
}

.rolex-model-carousel__bg-img img{
    height: 100%;
    width: 100%;
    z-index: -4;
    @media screen and (max-width: 991px){
        height: calc(300rem/16);
    }
}

