.rolex-watch-specification__img img{
    width: 100%;
}

.rolex-watch-specification__body{
    height: 100%;
    padding: calc(20rem/16) 5vw;

    display: flex;
    flex-direction: column;
    justify-content: center;

@media screen and (min-width: 992px){
    padding-left: 10vw;
    padding-right: 10vw;
}


@media screen and (min-width: 768px){
    padding-left: 5vw;
    padding-right: 5vw;
}
}

.rolex-watch-specification__content{
    line-height: 1.8;
    font-size: calc(12rem/16);
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(14.4rem/16);
    }
}

.rolex-watch-specification__hl{
    font-size: calc(12rem/16);
    text-transform: uppercase;
    font-family: var(--font-regular);

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}

.rolex-watch-specification__value{
    font-size: calc(12rem / 16);
    color: var(--color-rolex-default);
    font-family: var(--font-light);
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        font-size:  calc(14rem/16);
    }
}
