.container-rolex--small.rolex-flagship-video__content{
    margin-top: calc(20rem/16);


    @media screen and (min-width: 768px){
        margin-top: calc(50rem/16);
    }

    @media screen and (max-width: 767px){
        padding: 0 calc(20rem/16);
    }
    line-height: 1.8;
    font-size: calc(16rem/16);
}
