@media screen and (max-width: 1068px) {
    .filter-overlay {
        transform: translateX(-100%);
        background: #f8f8f8;
        z-index: 150;
        overflow-y: scroll;
        padding: calc(80rem / 16) 5vw 0 5vw;
        position: fixed;
        inset: 0;
        transition: transform 0.25s ease-in;
    }

    .filter-overlay.is-open {
        transform: translateX(0);
    }

    .filter-overlay__toggle {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: end;
        padding-right: 5vw;
        font-size: calc(12rem / 16);
        color: var(--color-rolex-primary);
        margin-bottom: calc(20rem / 16);
    }

    .filter-overlay__toggle__icon {
        transform: rotate(45deg);
        padding-left: calc(4rem / 16);
    }

    .filter-overlay__close {
        position: absolute;
        display: block;
        font-size: calc(18rem / 16);
        top: calc(12rem / 16);
        right: calc(12rem / 16);
    }
    .rolex-form__reset {
        display: none;
    }
    .rolex-form__bottom {
        background: #fff;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(100rem/16);
        padding: 0 5vw;
        display: flex;
        align-items: center;
        margin-left: -5vw;
        width: 100vw;
    }
}

@media screen and (max-width: 767px){
    .rolex-form__bottom {
        height: calc(80rem/16)!important;
    }
}

@media screen and (min-width: 1068px) {
    .filter-overlay__close,
    .filter-overlay__toggle,
    .rolex-form__bottom {
        display: none;
    }
    .rolex-form__reset {
        display: flex;
        align-items: center;
    }
}

.rolex-form__loading {
    position: absolute;
    inset: 0;
    background-color: #ffffff50;
    z-index: 153;
    text-align: center;
    color: var(--color-rolex-primary);
}
