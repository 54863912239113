.rolex-push-banner:hover img{
    overflow: hidden;
    transform: scale(1.05);
}

.rolex-push-banner img{
    overflow: hidden;
    transition: transform 0.3s ease-out;
    transform: scale(1);
}

.rolex-push-banner__img{
    overflow: hidden;
}
.rolex-push-banner__content{
    position: absolute;
    transform: translateX(-50%);
    top: 0%;
    left: 50%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.rolex-push-banner__img:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #111;
    opacity: 0.5;
    width: 100%;
}

.rolex-push-banner__img img{
    width: 100%;
}


.rolex-push-banner__subtitle{
    text-transform: uppercase;
    margin-bottom: calc(20rem/16);
    font-size: calc(11rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}

.rolex-push-banner__title{
    font-size: calc(24rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(38rem/16);
    }
}