.rolex-form__input-group {
    margin-top: calc(50rem / 16);
}

.rolex-form__heading,
.rolex-form__heading--small {
    text-transform: uppercase;
    font-family: var(--font-bold);
    margin-bottom: calc(20rem / 16);
}

.rolex-form__heading {
    font-size: calc(22rem / 16);
}

.rolex-form__heading--small {
    font-size: calc(16rem / 16);
}

.rolex-form__list-item {
    font-size: calc(15rem / 16);
    margin-bottom: calc(15rem / 16);
    transition: color 0.15s ease-in;
}

.rolex-form__list-item:hover {
    color: var(--color-rolex-primary);
}

/* only for tablet */
@media screen and (min-width: 768px) and (max-width: 1068px) {
    .rolex-form__filters {
        display: flex;
    }

    .rolex-form__filters--right,
    .rolex-form__filters--left {
        width: 50%;
    }

    .rolex-form__filters--right > .rolex-form__input-group {
        margin-top: 0;
    }
}
