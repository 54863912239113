.modal-header{
    padding: calc(15rem/16) calc(30rem/16);
}

.modal-body{
    padding: calc(15rem/16) calc(30rem/16);

}
.modal-title{
    font-size: calc(25rem/16);
}